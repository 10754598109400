import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./features/app/view/App.tsx";
import "./index.css";
import { HeroUIProvider } from "@heroui/react";
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import {
  authRepository,
  FailureView,
  profileRepository,
  RouterFailureView,
  ServerFailure,
  SuspenseLoading,
  useStore,
} from "./core/core";
import OtpVerification from "./features/auth/view/OtpVerification.tsx";
import Auth from "./features/auth/view/Auth.tsx";
import Phone from "./features/auth/view/Phone.tsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PaymentResult } from "./features/payment/result/view/PaymentResult.tsx";
import { PaymentCheckout } from "./features/payment/checkout/view/Checkout.tsx";
import { ReferralRoute } from "./features/referral/view/ReferralRoute.tsx";
import { TermsPage } from "./features/terms/view/Terms.tsx";
import { PrivacyPage } from "./features/privacy/view/Privacy.tsx";
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "react-error-boundary";

// eslint-disable-next-line react-refresh/only-export-components
const Register = lazy(() => import("./features/auth/view/Register.tsx"));

// Disable console logs in production
try {
  if (import.meta.env.PROD) {
    console.log = () => {};
  }
} catch (error) {
  //
}

try {
  if (import.meta.env.PROD) {
    Sentry.init({
      dsn: "https://39f3e2ddbeeeff3bff74186a1fab881f@o4509089087422464.ingest.us.sentry.io/4509089090109440",
      debug: import.meta.env.DEV,
      environment: import.meta.env.MODE,
      // TODO:
      // release: ,
      // sampleRate: ,
      // tracesSampleRate: ,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
      profilesSampleRate: 0,
    });
  }
} catch (error) {
  console.error("Sentry initialization failed.", error);
}

const redirectWithRedirectTo = (url: string) => {
  // Keep the redirect to if it already exists
  const prevRedirectTo = new URL(location.href).searchParams.get("redirect_to");

  const redirectTo =
    prevRedirectTo ??
    location.toString().replace(location.protocol + "//" + location.host, "");

  const query =
    redirectTo === "/" ? "" : `?redirect_to=${encodeURIComponent(redirectTo)}`;

  return redirect(url + query);
};

const profileCompletedGuard = async (isCompleteProfileRoute: boolean) => {
  const storeState = useStore.getState();

  if (storeState.profile !== undefined) {
    if (storeState.profile.profileStatus === "NeedToComplete") {
      return isCompleteProfileRoute
        ? null
        : redirectWithRedirectTo("/complete-profile");
    }

    return isCompleteProfileRoute ? redirect("/") : null;
  }

  const token = authRepository.getAccessToken();
  if (token) {
    const profile = await profileRepository.get();

    return profile.fold({
      onSuccess: (data) => {
        storeState.setProfile(data);

        try {
          Sentry.setUser({
            id: data.id,
            username: data.phone,
          });
        } catch (error) {
          console.error("Sentry setUser failed.", error);
        }

        if (data.profileStatus === "NeedToComplete") {
          return isCompleteProfileRoute
            ? null
            : redirectWithRedirectTo("/complete-profile");
        }

        return isCompleteProfileRoute ? redirect("/") : null;
      },
      onFailure: (failure) => {
        throw failure;
      },
    });
  }

  return redirectWithRedirectTo("/auth/join");
};

const router = createBrowserRouter([
  {
    path: "/terms",
    element: <TermsPage />,
  },
  {
    path: "/privacy",
    element: <PrivacyPage />,
  },
  {
    path: "/auth",
    loader: () => {
      return authRepository.getAccessToken()
        ? redirectWithRedirectTo("/")
        : null;
    },
    children: [
      {
        index: true,
        element: <Auth />,
      },
      {
        path: "join",
        element: <Auth />,
      },
      {
        path: "phone",
        element: <Phone />,
      },
      {
        path: "phone/verification/:phone/:token",
        element: <OtpVerification />,
      },
    ],
  },
  {
    path: "/complete-profile",
    element: (
      <Suspense fallback={<SuspenseLoading useVw />}>
        <Register />
      </Suspense>
    ),
    loader: () => profileCompletedGuard(true),
  },
  {
    path: "/invite/:inviteCode",
    element: <ReferralRoute />,
  },
  {
    // TODO: How to show loading/
    // element: (
    //   <div className="flex flex-col h-screen justify-center items-center">
    //     <Spinner />
    //   </div>
    // ),
    loader: () => profileCompletedGuard(false),
    errorElement: <RouterFailureView />,
    children: [
      {
        path: "/:roomId?",
        element: <App />,
      },
      {
        path: "/payment/result/:paymentId",
        element: <PaymentResult />,
      },
      {
        path: "/payment/checkout/:selectedPlanId",
        element: <PaymentCheckout />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <ErrorBoundary
    fallback={
      <FailureView
        failure={new ServerFailure(0, null)}
        onRetry={location.reload}
      />
    }
  >
    <React.StrictMode>
      <HeroUIProvider>
        <RouterProvider router={router} />
      </HeroUIProvider>
      <ToastContainer position="top-center" />
    </React.StrictMode>
  </ErrorBoundary>
);

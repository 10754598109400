import { ReactNode, useState } from "react";
import {
  AnalyticsEvents,
  logAnalyticsEvent,
} from "../../../../firebase/firebase";

export const SubscriptionTabs: React.FC<{ children: ReactNode[] }> = ({
  children,
}) => {
  const [selectedTab, setSelectedTab] = useState<"weekly" | "monthly">(
    "monthly"
  );

  return (
    <div className="relative">
      <div
        className={
          "absolute bg-primary h-8 w-1/2 rounded-md transition-all top-1 " +
          (selectedTab === "monthly" ? "left-1 right-1/2" : "left-1/2 right-1")
        }
      />
      <div className="flex flex-row h-10 p-1 rounded-lg bg-default-100 gap-1 mb-4">
        <div
          className={
            "flex-grow rounded-lg flex justify-center items-center text-sm cursor-pointer z-20 transition-colors " +
            (selectedTab === "weekly"
              ? "text-primary-foreground"
              : "text-default-500 hover:text-default-400")
          }
          onClick={() => {
            setSelectedTab("weekly");
            logAnalyticsEvent(AnalyticsEvents.paymentPlans.tabClicked, {
              tab: "weekly",
            });
          }}
        >
          هفتگی
        </div>
        <div
          className={
            "flex-grow rounded-md flex justify-center items-center text-sm cursor-pointer z-20 transition-colors " +
            (selectedTab === "monthly"
              ? "text-primary-foreground"
              : "text-default-500 hover:text-default-400")
          }
          onClick={() => {
            setSelectedTab("monthly");
            logAnalyticsEvent(AnalyticsEvents.paymentPlans.tabClicked, {
              tab: "monthly",
            });
          }}
        >
          ماهانه
        </div>
      </div>
      {selectedTab === "weekly" ? children[0] : children[1]}
    </div>
  );
};
